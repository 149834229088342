import React from "react";
import './index.less'
import { Link } from "react-router-dom";

const FooterLayout = () => {

    return (
        <footer>
            <div className="footer-layout">
                <div className="footer-list">
                    <Link to='/'>
                        <p>© 2023 SnapThreads.io</p>
                    </Link>
                    <Link to='/term-of-service'>
                        <p>Term of Service</p>
                    </Link>
                    <Link to='/privacy'>
                        <p>Privacy Policy</p>
                    </Link>
                    <Link to='/copyright'>
                        <p>Copyright</p>
                    </Link>

                </div>
            </div>
        </footer>
    )
}

export default FooterLayout
// import React from "react";
import './index.less'
import { Link, useHistory, useLocation } from "react-router-dom";
// import LogoDownLoader from '../../assets/images/Logo.svg'

export function ReloadButton() {
    const history = useHistory();
    history.push('/');
}

const HeaderLayout = () => {

    return (
        <div className="header-layout">
        <div className="container">
            <div className="header-content">
               
                <div className="header-tab">
                    <Link to='/'>
                        <p>Home</p>
                    </Link>
                    <Link to='/contact'>
                        <p>Contact</p>
                    </Link>
                    <Link to='/about-us'>
                        <p>About</p>
                    </Link>
                    <a href='/faq'>
                        <p>FAQ</p>
                    </a>

                </div>
            </div>
        </div>
    </div >

    )
}

export default HeaderLayout
// import React from 'react';
// import logo from './logo.svg';
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React, { Suspense, lazy } from "react";
// import MainPageLayout from './screens/videoReels/VideoReels';
import HeaderLayout from "./screens/header/Header";
import Footer from "./screens/footer/Footer";
// import TermOfServiceLayout from './screens/docs/TermOfService';
// import PrivacyLayout from './screens/docs/Privacy';
// import AboutUsLayout from './screens/docs/AboutUs';
// import Contact from './screens/contact/contact';
// import FAQLayout from './screens/docs/FAQ';
// import CopyrightLayout from './screens/docs/Copyright';
// import CallSimulationPrivacyLayout from './screens/docs/CallSimulationPrivacy'

const MainPageLayout = lazy(() => import("./screens/videoReels/VideoReels"));
const TermOfServiceLayout = lazy(() => import("./screens/docs/TermOfService"));
const PrivacyLayout = lazy(() => import("./screens/docs/Privacy"));
const AboutUsLayout = lazy(() => import("./screens/docs/AboutUs"));
const Contact = lazy(() => import("./screens/contact/contact"));
const FAQLayout = lazy(() => import("./screens/docs/FAQ"));
const CopyrightLayout = lazy(() => import("./screens/docs/Copyright"));
const CallSimulationPrivacyLayout = lazy(
  () => import("./screens/docs/CallSimulationPrivacy")
);

function App() {

  return (
    <div className="App">
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <HeaderLayout />
          <div className="container-app">
            <Switch>
              <Route path={"/"} exact component={MainPageLayout} />
              <Route
                path={"/term-of-service"}
                component={TermOfServiceLayout}
              />
              <Route path={"/privacy"} component={PrivacyLayout} />
              <Route path={"/about-us"} component={AboutUsLayout} />
              <Route path={"/contact"} component={Contact} />
              <Route path={"/faq"} component={FAQLayout} />
              <Route path={"/copyright"} component={CopyrightLayout} />
              <Route
                path={"/Call-Simulation-Privacy"}
                component={CallSimulationPrivacyLayout}
              />
            </Switch>
          </div>
          <Footer />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
